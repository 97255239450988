<div class="block">
    <div class="heading-faq">
        <span class="head-faq">Frequently Asked Questions</span>
    </div>
    <div>
        <mat-expansion-panel hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title class="title">
                What is LUMINA?
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <p class="content">
                    LUMINA is the DNV’s BI tool for measurement and benchmarking of customers’ management system performance.<br>
                    LUMINA analyses relevant information hidden in the audit data of your company and thousands of other companies within your sector and across the globe. Thus, LUMINA provides a reliable benchmark of your performance and a better awareness of your industry and beyond. LUMINA is not meant to replace the Audit result/report but rather to be a complement to it: Audit report >> gap towards compliance, LUMINA report >> gap towards competitors/benchmark group<br>
                    When working together, they provide insights in terms of compliance and competitive advantage over peers.<br>
                    Our unique LUMINA methodology has been developed in cooperation with GFK, a leading global research and statistics company.
                </p>
            </ng-template>
        </mat-expansion-panel>

        <!-- <p class="part">Administration</p>
        <mat-accordion *ngFor="let faq of administrationFAQ; let i=index" multi="false">
            <mat-expansion-panel class="expansion-panel" hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title class="title">
                    {{faq.ques}}
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                    <p class="content" [innerHTML]="faq.ans"></p>
                </ng-template>
            </mat-expansion-panel>
        </mat-accordion> -->

        <p class="part">LUMINA Analytics</p>
        <mat-accordion *ngFor="let anal of luminaAnalyticsFAQ; let i=index" multi="false">
            <mat-expansion-panel class="expansion-panel" hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title class="title">
                    {{anal.ques}}
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                    <p class="content" [innerHTML]="anal.ans"></p>
                </ng-template>
            </mat-expansion-panel>
        </mat-accordion>

        <p class="part">Data Security</p>
        <mat-accordion *ngFor="let security of dataSecurityFAQ; let i=index" multi="false">
            <mat-expansion-panel class="expansion-panel" hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title class="title">
                    {{security.ques}}
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                    <p class="content" [innerHTML]="security.ans"></p>
                </ng-template>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>
