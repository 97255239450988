import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { ApiService } from './services/api.service';
import { LuminaAnalyticsComponent } from './components/lumina-analytics/lumina-analytics.component';
import { AdminComponent } from './components/admin/admin.component';
import { TutorialComponent } from './components/tutorial/tutorial.component';
import { LuminaSupportComponent } from './components/lumina-support/lumina-support.component';
import { LuminaFaqComponent } from './components/lumina-faq/lumina-faq.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatDialogModule } from '@angular/material/dialog';
import { TutorialVideoComponent } from './components/tutorial/tutorial-video/tutorial-video.component';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { SitemapComponent } from './components/sitemap/sitemap.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AuthenticationService } from './services/authentication.service';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { LoginComponent } from './components/login/login.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { NgxPaginationModule } from 'ngx-pagination';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SnackbarComponent } from './snackbar/snackbar.component';
import { SnackbarService } from './services/snackbar.service';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { HealthstatusComponent } from './healthstatus/healthstatus.component';
import { PowerBIEmbedModule } from 'powerbi-client-angular';
import { TelemetryMonitoringService } from './services/logging.service';
import { HomeVideoComponent } from './components/home/home-video/home-video.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ToastrModule } from 'ngx-toastr';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LuminaAnalyticsComponent,
    AdminComponent,
    TutorialComponent,
    LuminaSupportComponent,
    LuminaFaqComponent,
    TutorialVideoComponent,
    UnauthorizedComponent,
    SitemapComponent,
    LoginComponent,
    SnackbarComponent,
    HealthstatusComponent,
    HomeVideoComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    MatButtonModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatIconModule,
    MatTooltipModule,
    FontAwesomeModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatTableModule,
    NgxPaginationModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatMenuModule,
    PowerBIEmbedModule,
    MatFormFieldModule,
    MatInputModule,
    ToastrModule.forRoot(),
  ],
  providers: [
    ApiService,
    AuthenticationService,
    SnackbarService,
    TelemetryMonitoringService,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
