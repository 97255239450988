import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminComponent } from './components/admin/admin.component';
import { HomeComponent } from './components/home/home.component';
import { LuminaAnalyticsComponent } from './components/lumina-analytics/lumina-analytics.component';
import { LuminaFaqComponent } from './components/lumina-faq/lumina-faq.component';
import { LuminaSupportComponent } from './components/lumina-support/lumina-support.component';
import { SitemapComponent } from './components/sitemap/sitemap.component';
import { TutorialComponent } from './components/tutorial/tutorial.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { LoginComponent } from './components/login/login.component';

import { AuthGuard } from './utility/auth.guard';
import { environment } from 'src/environments/environment';
import { HealthstatusComponent } from './healthstatus/healthstatus.component';

const routes: Routes = [
  {path: '', component:HomeComponent, canActivate: [AuthGuard], pathMatch: 'full'},
  {path: 'login', component: LoginComponent},
  {path: 'getHealth', component: HealthstatusComponent},
  {path: 'home', component: HomeComponent,canActivate: [AuthGuard]},
  {path: 'analytics', component: LuminaAnalyticsComponent,canActivate: [AuthGuard] },
  {path: 'tutorial', component: TutorialComponent,canActivate: [AuthGuard]},
  {path: 'admin', component: AdminComponent,canActivate: [AuthGuard]},
  {path: 'support', component: LuminaSupportComponent,canActivate: [AuthGuard]},
  {path: 'faq', component: LuminaFaqComponent,canActivate: [AuthGuard]},
  {path: 'unauthorized', component: UnauthorizedComponent},
  {path: 'sitemap', component: SitemapComponent,canActivate: [AuthGuard]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
