import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TutorialComponent } from '../tutorial.component';

@Component({
  selector: 'app-tutorial-video',
  templateUrl: './tutorial-video.component.html',
  styleUrls: ['./tutorial-video.component.css']
})
export class TutorialVideoComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<TutorialComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public sanitizer: DomSanitizer) { }

  public videoLink: any;

  ngOnInit(): void {
    this.videoLink = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.link);
  }

  public closeDialog() {
    this.dialogRef.close();
  }

}
