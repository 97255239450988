export class LuminaConstants {
    static homeURL(homeURL: any) {
      throw new Error('Method not implemented.');
    }
    public static createSuccessMessage:string = "Contact details saved successfully";
    public static updateSuccessMessage:string = "Contact details updated successfully";
    public static errorMessage:string = "Error! Please contact administrator";
    public static duplicateEmailErrorMessage:string = "Error! Duplicate Contact";
    public static PROFILE_URL:string = "https://id.veracity.com/profile";
    public static HOME_URL:string = "https://www.dnv.com/";
    public static APP_VERSION:string = "1.0.0";


    
}
