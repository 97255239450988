import { Component, OnInit } from '@angular/core';
import { LuminaConstants } from 'src/app/lumina-constants';
import { ApiService } from 'src/app/services/api.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { SnackbarService } from 'src/app/services/snackbar.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

  constructor(private api: ApiService, private authenticationService: AuthenticationService, private snack: SnackbarService) { }

  public partyNumber= '';
  public contactsArray: any[] = [];
  public contactsNameArray: any[] = [];
  public contactsNameArray1: any[] = [];
  public p: number = 1;
  public contactName = '';
  public viewMode = 'all';
  public contactPartyNumber = '';
  public contactFirstName = '';
  public contactLastName = '';
  public contactEmail = '';
  public matchedIndex;
  public searchFlag = 0;
  public isContactLuminaAdmin = false;
  public isAccountLuminaAccess = false;
  public clickedContactDetails;
  public loggedUserContactDetails;
  public contactPartOfAccnts: any[] = [];
  public contactAvailableAccnts: any[] = [];
  public loggedUserPartOfAccnts: any[] = [];
  public newAccountAdd = false;
  public showAddAccount = true;
  public modifiedLuminaAccess: any[] = [];
  public newAccountAddedData: any[] = [];
  public modifiedAccountsAdded: any[] = [];
  public activeContact = '';
  public newcontactFirstName = '';
  public newcontactLastName = '';
  public newcontactEmail = '';
  public newcontactConfirmEmail = '';
  public isnewContactLuminaAdmin = false;
  public dataLoaded = false;
  public dataCreateLoaded = false;
  public loggedUserEmail = '';
  public maxSize: number = 5;
  public directionLinks: boolean = true;
  public autoHide: boolean = true;
  public responsive: boolean = true;

  public isOneAccess = false;
  isNoAccounts = false;
  adminScreenLoaded = false;
  allContactsLoaded = false;

  ngOnInit(): void {
    this.viewMode = 'all';
    this.isNoAccounts = false;
    this.adminScreenLoaded = false;
   this.authenticationService.getUserValue().subscribe((value) => {
    this.loggedUserEmail = value.email;
    this.partyNumber = value.party_Number;
    this.api.getContactDetails(this.partyNumber, this.partyNumber).subscribe((data: any) => {
      const loggedUserAccountDetails = data;
      if(loggedUserAccountDetails.partOfAccounts.length === 0) {
        this.isNoAccounts = true;
        this.adminScreenLoaded = true;
      }
      else {
        this.adminScreenLoaded = true;
        this.getDetails();
      }
    },
    () => {
      this.snack.openSnackBar(LuminaConstants.errorMessage, '', 'Warn');
    });
  });
  }

  public getDetails() {
    
    if(this.loggedUserEmail){
      this.contactsNameArray = [];
        this.api.getContacts(this.partyNumber).subscribe((dataArray: any) => {
          this.allContactsLoaded = true;
          this.contactsArray = dataArray;
          this.setContactsName(this.contactsArray);
        },
        () => {
          this.snack.openSnackBar(LuminaConstants.errorMessage, '', 'Warn');
        });
    }
    
  }

  public setContactsName(arr) {
    for (let i = 0; i < arr.length; i++) {
      this.contactsNameArray.push({
        cname: arr[i].ContactFirstName+' '+arr[i].ContactLastName,
        cpartynumber: arr[i].ContactPartyNumber,
        cemail: arr[i].EmailAddress,
        cpartyid: arr[i].ContactPartyId,
        cfname: arr[i].ContactFirstName,
        clname: arr[i].ContactLastName
      });
    }
    this.contactsNameArray1 = this.contactsNameArray;
  }

  public search() {
    this.searchFlag = 1;
    this.p = 1;
    if(this.contactName === '') {
       this.contactsNameArray = this.contactsNameArray1;
       this.searchFlag = 0;
     }
     else {
      this.contactsNameArray = this.contactsNameArray1.filter(res => {
        return res.cname.toLocaleLowerCase().match(this.contactName.toLocaleLowerCase());
      });
     }    
  }

  public scrollToTop() {
    window.scrollTo(0,0);
  }

  public addNewContact() {
    this.searchFlag = 0; //added for view after create
    this.dataCreateLoaded = false;
    this.loggedUserPartOfAccnts = [];
    this.activeContact = '';
    this.newcontactFirstName = '';
    this.newcontactLastName = '';
    this.newcontactEmail = '';
    this.newcontactConfirmEmail = '';
    this.isnewContactLuminaAdmin = false;
    this.api.getContactDetails(this.partyNumber, this.partyNumber).subscribe((data: any) => {
      this.loggedUserContactDetails = data;
      this.loggedUserPartOfAccnts = this.loggedUserContactDetails.partOfAccounts;
      for(let laccount of this.loggedUserPartOfAccnts) { // done for false in new contact
        laccount.hasLuminaAccess = false;
      }
      this.dataCreateLoaded = true;
    },
    () => {
      this.dataCreateLoaded = true;
      this.snack.openSnackBar(LuminaConstants.errorMessage, '', 'Warn');
    });
  }

  public createNewContact() {
    this.searchFlag = 0; //added for view after create
    this.dataCreateLoaded = false;
    if(this.loggedUserPartOfAccnts.length > 0) {
      for(let ndata of this.loggedUserPartOfAccnts) {
        if(ndata.hasLuminaAccess === true) {
          this.newAccountAddedData.push({
            accountPartyNumber: ndata.PartyNumber,
            hasLuminaAccess: ndata.hasLuminaAccess
          })
        }
      }
    }

    const newDataToSend = {
      firstName: this.newcontactFirstName,
      lastName: this.newcontactLastName,
      email: this.newcontactEmail,
      luminaAdmin: this.isnewContactLuminaAdmin,
      luminaTnCAccepted: false,
      associateAccounts: this.newAccountAddedData
    };

    this.api.createContactDetails(newDataToSend).subscribe((res: any) => {
      const newContactPartyNumber = res.partyNumber;
      this.snack.openSnackBar(LuminaConstants.createSuccessMessage, '', 'Success');

      this.contactsNameArray = [];
        this.api.getContacts(this.partyNumber).subscribe((dataArray: any) => {
          this.contactsArray = dataArray;
          this.setContactsName(this.contactsArray);
          this.showNewContactView(newContactPartyNumber); //added for view after create
        },
        () => {
          this.dataCreateLoaded = true;
          this.snack.openSnackBar(LuminaConstants.errorMessage, '', 'Warn');
        });
        
    },
    (err) => {
      this.dataCreateLoaded = true;
      if(err.error.message) {
        const errorMessage = err.error.message.split(":")[0];
        if(errorMessage === 'Duplicate Contact email') {
          this.snack.openSnackBar(LuminaConstants.duplicateEmailErrorMessage, '', 'Warn');
        } else {
          this.snack.openSnackBar(LuminaConstants.errorMessage, '', 'Warn');
        }
      }
      else {
        this.snack.openSnackBar(LuminaConstants.errorMessage, '', 'Warn');
      }
    });
    this.newAccountAddedData = [];
  }

  public showNewContactView(newPartyNumber) { //added for view after create
    this.viewMode = 'view';
    const matchedNewContact = this.contactsNameArray.find(item => item.cpartynumber === newPartyNumber);
    this.showContactDetails(matchedNewContact);
    this.dataCreateLoaded = true;
  }

  public showContactDetails(ele) {
    this.dataLoaded = false;
    this.activeContact = ele.cpartynumber;
    this.newAccountAdd = false;
    this.showAddAccount= true;
    this.contactPartyNumber = ele.cpartynumber;
    this.contactFirstName = ele.cfname;
    this.contactLastName = ele.clname;
    this.contactEmail = ele.cemail;
    this.matchedIndex = this.contactsArray.indexOf(this.contactsArray.find(item => item.ContactPartyNumber === this.contactPartyNumber)) + 1;
    if(this.searchFlag === 1) {
      this.p = 1;
    }
    else {
      this.p = Math.ceil(this.matchedIndex / 10);
    }
    this.api.getContactDetails(this.partyNumber, this.contactPartyNumber).subscribe((data: any) => {
      this.clickedContactDetails = data;
      this.showClickedContactDetails();
    },
    () => {
      this.dataLoaded = true;
      this.snack.openSnackBar(LuminaConstants.errorMessage, '', 'Warn');
    });
  }

  public showClickedContactDetails() {
    this.isContactLuminaAdmin = this.clickedContactDetails.isLuminaAdmin;
    this.contactPartOfAccnts = this.clickedContactDetails.partOfAccounts;
    this.contactAvailableAccnts = this.clickedContactDetails.availableAccounts;
    this.dataLoaded = true;
  }

  public updateContact() {
    this.dataLoaded = false;
    this.searchFlag = 0;
    this.p = Math.ceil(this.matchedIndex / 10);
    this.showAddAccount = true;

    if(this.contactAvailableAccnts.length > 0) {
      for(let mdata of this.contactAvailableAccnts) {
        if(mdata.hasLuminaAccess === true) {
          this.modifiedAccountsAdded.push({
            accountPartyNumber: mdata.PartyNumber,
            hasLuminaAccess: mdata.hasLuminaAccess
          })
        }
      }
    }

    if(this.contactPartOfAccnts.length > 0) {
      for(let cdata of this.contactPartOfAccnts) {
        this.modifiedLuminaAccess.push({
          accountPartyNumber: cdata.PartyNumber,
          hasLuminaAccess: cdata.hasLuminaAccess
        })
      }
    }
    
    const dataToSend = {
      partyNumber: this.contactPartyNumber,
      firstName: this.contactFirstName,
      lastName: this.contactLastName,
      luminaAdmin: this.isContactLuminaAdmin,
      associateAccounts: this.modifiedAccountsAdded,
      modifyLuminaAccess: this.modifiedLuminaAccess.concat(this.modifiedAccountsAdded)
    };
    this.api.updateContactDetails(dataToSend).subscribe((res) => {
      this.snack.openSnackBar(LuminaConstants.updateSuccessMessage, '', 'Success');
      this.contactsNameArray = [];
        this.api.getContacts(this.partyNumber).subscribe((dataArray: any) => {
          this.contactsArray = dataArray;
          this.setContactsName(this.contactsArray);
          this.getUpdatedContactData();
        },
        () => {
          this.dataLoaded = true;
          this.snack.openSnackBar(LuminaConstants.errorMessage, '', 'Warn');
        });
    },
    () => {
      this.dataLoaded = true;
      this.snack.openSnackBar(LuminaConstants.errorMessage, '', 'Warn');
    });
    this.modifiedLuminaAccess = [];
    this.modifiedAccountsAdded = [];
  }

  public getUpdatedContactData() {
    const matchedContact = this.contactsNameArray.find(item => item.cpartynumber === this.contactPartyNumber);
    this.activeContact = matchedContact.cpartynumber;
    this.contactPartyNumber = matchedContact.cpartynumber;
    this.contactFirstName = matchedContact.cfname;
    this.contactLastName = matchedContact.clname;
    this.contactEmail = matchedContact.cemail;
    this.api.getContactDetails(this.partyNumber, this.contactPartyNumber).subscribe((data: any) => {
      this.clickedContactDetails = data;
      this.showClickedContactDetails();
    },
    () => {
      this.dataLoaded = true;
      this.snack.openSnackBar(LuminaConstants.errorMessage, '', 'Warn');
    });
  }

  public addNewAccount() {
    this.newAccountAdd = true;
    this.showAddAccount = false;
  }

  public getAddress(addr) {
    const fullAddress = addr.AddressLine1?addr.AddressLine1:''+" "+addr.AddressLine2?addr.AddressLine2:''+" "+addr.AddressLine3?addr.AddressLine3:''+" "+addr.AddressLine4?addr.AddressLine4:'';
    return fullAddress;
  }

  public showUpdatedContacts() {
    this.searchFlag = 0;
    this.getDetails();
  }

  public checkAccess() {
    this.isOneAccess = false;
    if(this.loggedUserPartOfAccnts.length > 0) {
      for(let ndata of this.loggedUserPartOfAccnts) {
        if(ndata.hasLuminaAccess === true) {
          this.isOneAccess = true;
          break;
        }
      }
    }
  }

}
