import { Component, OnInit } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-sitemap',
  templateUrl: './sitemap.component.html',
  styleUrls: ['./sitemap.component.css']
})
export class SitemapComponent implements OnInit {

  public checkAdmin = false;

  constructor(private authenticationService: AuthenticationService , private appComp:AppComponent) {
  }

  ngOnInit(): void {
    this.authenticationService.getUserValue().subscribe((value) => {
      this.checkAdmin = value.isLuminaAdmin;
    });
    
  }

  public scrollToTop() {
    window.scrollTo(0,0);
  }

  public navigateToAnalytics(linkName: any){
    this.appComp.navChange(linkName);
  }

}
