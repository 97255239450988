import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TutorialVideoComponent } from './tutorial-video/tutorial-video.component';
import { TelemetryMonitoringService } from 'src/app/services/logging.service';

@Component({
  selector: 'app-tutorial',
  templateUrl: './tutorial.component.html',
  styleUrls: ['./tutorial.component.css']
})
export class TutorialComponent implements OnInit {

  constructor(public dialog: MatDialog, public appInsight: TelemetryMonitoringService) { }

  ngOnInit(): void {
    // this.appInsight.logEvent('Visited Tutorials page at : ', {
    //   Signin_Date: new Date(),
    // });
    this.appInsight.logEvent('ProfileDetails', {
      User: this.appInsight.rootScope.displayName,
      Email: this.appInsight.rootScope.email
    });
    this.trackPageView();
  }

  public openVideo(videoType: string) {
    if(videoType === 'basic') { // START-UP GUIDE
      const dialogRef = this.dialog.open(TutorialVideoComponent, {
        disableClose: false,
        data: { link: 'https://brandcentral.dnvgl.com/mars/embed?o=D0663719A4D160E2&c=10651&a=N'},
        width: '70%',
        height: '90%'
      });

    }

    if(videoType === 'methodology') { // METHODOLOGY
      const dialogRef = this.dialog.open(TutorialVideoComponent, {
        disableClose: false,
        data: { link: 'https://brandcentral.dnvgl.com/mars/embed?o=2FAB2FFFD7BCC6C7&c=10651&a=N'},
        width: '70%',
        height: '90%'
      });

    }

    if(videoType === 'analytics') { // ANALYTICS
      const dialogRef = this.dialog.open(TutorialVideoComponent, {
        disableClose: false,
        data: { link: 'https://brandcentral.dnvgl.com/mars/embed?o=155520B6CDCFEBCA&c=10651&a=N'},
        width: '70%',
        height: '90%'
      });
  
    }
  }
  trackPageView(){
    const profileDetails = {
      email: this.appInsight.rootScope.email, 
      user: this.appInsight.rootScope.displayName
    }
    this.appInsight.logPageView("Tutorials page", profileDetails);
  }

}
