<div class="block" *ngIf="isNoAccounts && adminScreenLoaded">
    <span class="no-account">There are no associated Accounts for your profile.</span>
</div>
<div class="block" *ngIf="!isNoAccounts && adminScreenLoaded">

    <div *ngIf="viewMode === 'all'">
        <div>
            <p class="contact">Manage Contacts</p>
            <input class="form-control" type="text" name="contactName" [(ngModel)]="contactName" (ngModelChange)="search()">
        </div>
        <div *ngIf="contactsArray.length === 0 && allContactsLoaded; else contactfound">
            <span class="no-account">No Contacts found</span>
        </div>
        <ng-template #contactfound>
        <section class="container">
            <div class="element" *ngFor="let element of contactsNameArray | paginate: { itemsPerPage: 30, currentPage: p }; let i=index"
             (click)="showContactDetails(element);scrollToTop();viewMode = 'view'" [ngClass]="{'activeName': activeContact === element.cpartynumber}">
                <span class="name">{{element.cname}}</span>
            </div>
        </section>
        <div class="controls">
            <pagination-controls [maxSize]="maxSize"
            [directionLinks]="directionLinks"
            [autoHide]="autoHide"
            [responsive]="responsive" (pageChange)="p = $event" previousLabel="" nextLabel=""></pagination-controls>
        </div>
        </ng-template>
        <div class="button-new">
            <button class="btn cursor" (click)="addNewContact();scrollToTop();viewMode = 'create';isOneAccess=false">
                <span class="goto">Add New</span>
            </button>
        </div>
    </div>

    <div *ngIf="viewMode === 'view'">
        <div class="view-container">
            <div class="view-names">
                <div>
                    <p class="contact">Manage Contacts</p>
                    <input class="form-control" type="text" name="contactName" [(ngModel)]="contactName" (ngModelChange)="search()">
                </div>
                <section class="container">
                    <div class="element" *ngFor="let element of contactsNameArray | paginate: { itemsPerPage: 10, currentPage: p }; let i=index"
                     (click)="showContactDetails(element);scrollToTop();viewMode = 'view'" [ngClass]="{'activeName': activeContact === element.cpartynumber}">
                        <span class="name">{{element.cname}}</span>
                    </div>
                </section>
                <div class="controls-left">
                    <pagination-controls [maxSize]="maxSize"
                    [directionLinks]="directionLinks"
                    [autoHide]="autoHide"
                    [responsive]="responsive" (pageChange)="p = $event" previousLabel="" nextLabel=""></pagination-controls>
                </div>
                <div class="button-new-small">
                    <button class="btn1 cursor" (click)="addNewContact();scrollToTop();viewMode = 'create';isOneAccess=false">
                        <span class="goto">Add New</span>
                    </button>
                </div>
            </div>
            
            <div>
                <p class="contact details">Contact Details</p>
                <div class="contact-form">
                    <mat-spinner class="spinner" *ngIf="!dataLoaded" [diameter]="50"></mat-spinner>
                    <div *ngIf="dataLoaded">

                    <div class="detail-container">
                        <div class="first-details">
                            <p>First Name <span class="star">*</span></p>
                            <input class="form-control" type="text" name="contactFirstName" [(ngModel)]="contactFirstName" required>
                        </div>
                        <div class="first-details">
                            <p>Last Name</p>
                            <input class="form-control" type="text" name="contactLastName" [(ngModel)]="contactLastName">
                        </div>
                        <div class="first-details">
                            <p>Email</p>
                            <input class="form-control disable-email" type="text" name="contactEmail" [(ngModel)]="contactEmail" readonly>
                        </div>  
                    </div>
                    <div class="admin-slide">
                        <p>Make Admin</p>
                        <mat-slide-toggle [(ngModel)]="isContactLuminaAdmin"></mat-slide-toggle>
                    </div>
                    <div class="accnt-table">
                        <p class="account">Associated  Accounts</p>
                        <table *ngIf="contactPartOfAccnts.length > 0;else noData">
                            <thead>
                                <tr>
                                    <th>Account Name</th>
                                    <th>Address</th>
                                    <th>City</th>
                                    <th>State</th>
                                    <th>Country</th>
                                    <th>Lumina Access</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let account of contactPartOfAccnts">
                                    <td>{{account.OrganizationName}}</td>
                                    <td>{{getAddress(account)}}</td>
                                    <td>{{account.City}}</td>
                                    <td>{{account.State}}</td>
                                    <td>{{account.Country}}</td>
                                    <td class="checkbox"><mat-checkbox [(ngModel)]="account.hasLuminaAccess"></mat-checkbox></td>
                                </tr>
                            </tbody>
                        </table>
                        <ng-template #noData>
                            <p class="no-account">--No Accounts Found--</p>
                        </ng-template>
                    </div>
                    <div class="accnt-table" *ngIf="newAccountAdd">
                        <p class="account">Available  Accounts</p>
                        <table *ngIf="contactAvailableAccnts.length > 0;else noTable">
                            <thead>
                                <tr>
                                    <th>Account Name</th>
                                    <th>Address</th>
                                    <th>City</th>
                                    <th>State</th>
                                    <th>Country</th>
                                    <th>Lumina Access</th>
                                    <!-- <th>Add Account</th> -->
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let account of contactAvailableAccnts">
                                    <td>{{account.OrganizationName}}</td>
                                    <td>{{getAddress(account)}}</td>
                                    <td>{{account.City}}</td>
                                    <td>{{account.State}}</td>
                                    <td>{{account.Country}}</td>
                                    <td class="checkbox"><mat-checkbox [(ngModel)]="account.hasLuminaAccess"></mat-checkbox></td>
                                    <!-- <td class="checkbox"><mat-checkbox [(ngModel)]="account.hasLuminaAccess" [disabled]="!account.addAvailableAccnt"></mat-checkbox></td> -->
                                    <!-- <td class="checkbox"><mat-checkbox [(ngModel)]="account.addAvailableAccnt"></mat-checkbox></td> -->
                                </tr>
                            </tbody>
                        </table>
                        <ng-template #noTable>
                            <p class="no-account">--No Accounts Found--</p>
                        </ng-template>
                    </div>
                    <div class="action-button">
                        <button class="btns-save cursor" (click)="updateContact();scrollToTop();newAccountAdd=false"
                        [disabled]="contactFirstName === ''">
                            <span>Save</span>
                        </button>
                        <button class="btns-cancel cursor" (click)="showUpdatedContacts();scrollToTop();viewMode = 'all';newAccountAdd=false;showAddAccount=true;p=1;activeContact=''">
                            <span>Cancel</span>
                        </button>
                        <button class="btns-add cursor" (click)="addNewAccount();scrollToTop()" *ngIf="showAddAccount">
                            <span>Add Accounts</span>
                        </button>
                    </div>

                    </div>

                </div>
            </div>
        </div>
        
    </div>

    <div *ngIf="viewMode === 'create'">
        <div class="view-container">
            <div class="view-names">
                <div>
                    <p class="contact">Manage Contacts</p>
                    <input class="form-control" type="text" name="contactName" [(ngModel)]="contactName" (ngModelChange)="search()">
                </div>
                <div *ngIf="contactsArray.length === 0 && allContactsLoaded; else contactdetailfound">
                    <span class="no-account">No Contacts found</span>
                </div>
                <ng-template #contactdetailfound>
                <section class="container">
                    <div class="element" *ngFor="let element of contactsNameArray | paginate: { itemsPerPage: 10, currentPage: p }; let i=index"
                     (click)="showContactDetails(element);scrollToTop();viewMode = 'view'" [ngClass]="{'activeName': activeContact === element.cpartynumber}">
                        <span class="name">{{element.cname}}</span>
                    </div>
                </section>
                <div class="controls-left">
                    <pagination-controls [maxSize]="maxSize"
                    [directionLinks]="directionLinks"
                    [autoHide]="autoHide"
                    [responsive]="responsive" (pageChange)="p = $event" previousLabel="" nextLabel=""></pagination-controls>
                </div>
                </ng-template>
                <div class="button-new-small">
                    <button class="btn1 cursor" (click)="addNewContact();scrollToTop();viewMode = 'create';isOneAccess=false">
                        <span class="goto">Add New</span>
                    </button>
                </div>
            </div>
            
            <div>
                <p class="contact details">Contact Details</p>
                <div class="contact-form">

                    <mat-spinner class="spinner" *ngIf="!dataCreateLoaded" [diameter]="50"></mat-spinner>
                    <div *ngIf="dataCreateLoaded">
                    
                    <div class="detail-container">
                        <div class="first-details">
                            <p>First Name <span class="star">*</span></p>
                            <input class="form-control" type="text" name="newcontactFirstName" [(ngModel)]="newcontactFirstName" required>
                        </div>
                        <div class="first-details">
                            <p>Last Name <span class="star">*</span></p>
                            <input class="form-control" type="text" name="newcontactLastName" [(ngModel)]="newcontactLastName" required>
                        </div>
                        <div class="first-details">
                            <p>Email <span class="star">*</span></p>
                            <input class="form-control" type="email" name="newcontactEmail" [(ngModel)]="newcontactEmail" required>
                        </div>  
                    </div>
                    <div class="detail-container">
                        <div class="first-details">
                            <p>Confirm Email <span class="star">*</span></p>
                            <input class="form-control" type="email" name="newcontactConfirmEmail" [(ngModel)]="newcontactConfirmEmail" required>
                        </div>
                        <div class="admin-slide">
                            <p>Make Admin</p>
                            <mat-slide-toggle [(ngModel)]="isnewContactLuminaAdmin"></mat-slide-toggle>
                        </div>
                    </div>
                    <div class="accnt-table">
                        <p class="account">Available  Accounts</p>
                        <table *ngIf="loggedUserPartOfAccnts.length > 0;else noTable">
                            <thead>
                                <tr>
                                    <th>Account Name</th>
                                    <th>Address</th>
                                    <th>City</th>
                                    <th>State</th>
                                    <th>Country</th>
                                    <th>Lumina Access</th>
                                    <!-- <th>Add Account</th> -->
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let account of loggedUserPartOfAccnts">
                                    <td>{{account.OrganizationName}}</td>
                                    <td>{{getAddress(account)}}</td>
                                    <td>{{account.City}}</td>
                                    <td>{{account.State}}</td>
                                    <td>{{account.Country}}</td>
                                    <td class="checkbox"><mat-checkbox [(ngModel)]="account.hasLuminaAccess" (change)="checkAccess()"></mat-checkbox></td>
                                    <!-- <td class="checkbox"><mat-checkbox [(ngModel)]="account.hasLuminaAccess" [disabled]="!account.addAccnt"></mat-checkbox></td> -->
                                    <!-- <td class="checkbox"><mat-checkbox [(ngModel)]="account.addAccnt"></mat-checkbox></td> -->
                                </tr>
                            </tbody>
                        </table>
                        <ng-template #noTable>
                            <p class="no-account">--No Accounts Found--</p>
                        </ng-template>
                    </div>
                    <div class="action-button">
                        <button class="btns-save cursor" (click)="createNewContact();scrollToTop()"
                         [disabled]="newcontactFirstName === '' || newcontactLastName === '' || newcontactEmail === '' || newcontactConfirmEmail === '' || newcontactEmail !== newcontactConfirmEmail || isOneAccess === false">
                            <span>Save</span>
                        </button>
                        <button class="btns-cancel cursor" (click)="showUpdatedContacts();scrollToTop();viewMode = 'all';newAccountAdd=false;showAddAccount=true;p=1;activeContact=''">
                            <span>Cancel</span>
                        </button>
                    </div>

                    </div>

                </div>
            </div>
        </div>
    </div>

    
</div>
