export const environment = {
    production: false,
    ENV: 'UAT',
    APP_Ocp_Apim_Subscription_Key: '1ac5d7460b244622a2adab3ab8d0def8',
    LUMINA_APP_BASE_URL: "https://lumina-uat.dnv.com",
    LUMINA_APP_CLIENT_ID: "cc3d12c8-6626-422b-b2ec-daeef2561e53",
    LUMINA_APP_LOGOUT_URL: "https://developer.veracity.com/auth/logout",
    APP_BASE_URL: 'https://testapi.dnv.com/BALUMINA-API-UAT/v0/',
    SERVICE_ID:'28546f06-ebb1-46a2-9d08-ef125fccf4aa',
    appInsights: {
        instrumentationKey: 'aa36c52b-17ed-4eb3-954b-9b72d0d584c6',
      },
};
