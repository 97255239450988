<button
  mat-icon-button
  aria-label="close dialog"
  mat-dialog-close
  class="closeBtn"
>
  <mat-icon>close</mat-icon>
</button>
<div class="iframe">
    <iframe frameborder="0" allowfullscreen webkitallowfullscreen mozallowfullscreen  allow="accelerometer; camera; microphone; gyroscope; autoplay; clipboard-write; encrypted-media; picture-in-picture; fullscreen" width="100%" height="88%" [src]="videoLink"></iframe>
    <!-- <button class="btn" mat-dialog-close (click)="closeDialog()">Close</button> -->
</div>

