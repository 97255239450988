<div class="block">
    <div class="heading-sitemap">
        <span class="head-site">Sitemap</span>
    </div>
    <div class="container">
    <div class="row">
        <div class="sec-col">
            <h4>General</h4>
            <ul>
                <li><a routerLink="/home" routerLinkActive="active" ariaCurrentWhenActive="page" (click)="scrollToTop()" >Home</a></li>
                <li><a routerLink="/analytics" routerLinkActive="active" ariaCurrentWhenActive="page" (click)="scrollToTop(); navigateToAnalytics('analytics')">Lumina Analytics</a></li>
                <li><a routerLink="/tutorial" routerLinkActive="active" ariaCurrentWhenActive="page" (click)="scrollToTop()">Tutorial</a></li>
                <!--<li><a routerLink="/analytics-internal" routerLinkActive="active" ariaCurrentWhenActive="page" (click)="scrollToTop(); navigateToAnalytics('analytics-internal')">Analytics Internal</a></li>-->
            </ul>
        </div>
        <div class="sec-col" *ngIf="checkAdmin">
            <h4>Admin</h4>
            <ul>
                <li><a routerLink="/admin" routerLinkActive="active" ariaCurrentWhenActive="page" (click)="scrollToTop()">Manage Contacts</a></li>
            </ul>
        </div>
    </div>
    </div>
</div>
