<div class="block">
    <!-- <div class="heading-tutorial">
        <span class="tutorial">Watch the tutorial video for a quick start-up and effective use of Lumina</span>
    </div> -->

    <div class="card-container">
        <div class="card">
            <!-- <img class="card-image" src="../../assets/images/Basic.png" (click)="openVideo('basic')"> -->
            <!-- <iframe class="card-image" allowfullscreen  src="https://brandcentral.dnvgl.com/mars/embed?o=D0663719A4D160E2&c=10651&a=N"></iframe> -->
            <!-- <iframe class="card-image" frameborder="1" allowfullscreen webkitallowfullscreen mozallowfullscreen  allow="accelerometer; camera; microphone; gyroscope; autoplay; clipboard-write; encrypted-media; picture-in-picture; fullscreen" width="100%" height="100%" src="https://brandcentral.dnvgl.com/mars/embed?o=D0663719A4D160E2&c=10651&a=N"></iframe> -->
            <iframe class="card-image" frameborder="1" allowfullscreen webkitallowfullscreen mozallowfullscreen  allow="accelerometer; camera; microphone; gyroscope; autoplay; clipboard-write; encrypted-media; picture-in-picture; fullscreen" width="100%" height="100%" src="https://brandcentral.dnv.com/mars/embed?o=5DD5790FD5E7B98F&c=10651&a=N"></iframe>
            <h2>Introductory video</h2>
            <p>This video will introduce you to the application and take you through its main features.</p>
        </div>
        <!-- <div class="card"> -->
            <!-- <img class="card-image" src="../../assets/images/lumina-method.png" (click)="openVideo('methodology')"> -->
            <!-- <iframe class="card-image" allowfullscreen  src="https://brandcentral.dnvgl.com/mars/embed?o=155520B6CDCFEBCA&c=10651&a=N"></iframe> -->
            <!-- <iframe class="card-image" frameborder="1" allowfullscreen webkitallowfullscreen mozallowfullscreen  allow="accelerometer; camera; microphone; gyroscope; autoplay; clipboard-write; encrypted-media; picture-in-picture; fullscreen" width="100%" height="100%" src="https://brandcentral.dnvgl.com/mars/embed?o=2FAB2FFFD7BCC6C7&c=10651&a=N"></iframe>
            <h2>METHODOLOGY</h2>
            <p>The METHODOLOGY video will help you to get a better understanding of the metrics and indicators you will find in the application.</p>
        </div> -->
        <!-- <div class="card"> -->
            <!-- <img class="card-image" src="../../assets/images/analytics.png" (click)="openVideo('analytics')"> -->
            <!-- <iframe class="card-image" allowfullscreen  src="https://brandcentral.dnvgl.com/mars/embed?o=2FAB2FFFD7BCC6C7&c=10651&a=N"></iframe> -->
            <!-- <iframe class="card-image" frameborder="1" allowfullscreen webkitallowfullscreen mozallowfullscreen  allow="accelerometer; camera; microphone; gyroscope; autoplay; clipboard-write; encrypted-media; picture-in-picture; fullscreen" width="100%" height="100%" src="https://brandcentral.dnvgl.com/mars/embed?o=155520B6CDCFEBCA&c=10651&a=N"></iframe>
            <h2>ANALYTICS</h2>
            <p>The ANALYTICS video will show you how to set-up your queries, view the results and get more insights from your audit data.</p>
        </div> -->
    </div>
</div>
