import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import axios from 'axios';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { User } from '../models/user';
import { TelemetryMonitoringService } from './logging.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  screen:any
  isAccepted = true;
  isLoginSubject = new BehaviorSubject<boolean>(false);
  loggedPartyNumber: any;
  private userSubject: BehaviorSubject<User>;
  public user: Observable<User>;
  loggedInuser: any;

  public setUserInfoData(userInfoData: User) {
    this.userSubject.next(userInfoData);
  }

  getUserValue(): Observable<User> {
    return this.userSubject.asObservable();
  }

  isLoggedIn(): Observable<boolean> {
    return this.isLoginSubject.asObservable();
  }
  public get userValue(): User {
    return this.userSubject.value;
  }
  public get getUserSubject(): BehaviorSubject<User> {
    return this.userSubject;
  }

  constructor(
    private router: Router,
    private appInsight: TelemetryMonitoringService,
  ) {
    this.userSubject = new BehaviorSubject<User>(
      new User('', '', '', '', false, '', '', '')
    );
    this.user = this.userSubject.asObservable();
  }

  setLoginData = (data) => {
    return {
      type: 'LOGIN_SUCCESS',
      payload: data,
    };
  };

  logout(): void {
    window.open(environment.LUMINA_APP_LOGOUT_URL, '_self');
  }

  handleErrors(err) {
    if (err.response.status === 500) {
      window.location.href =
        'https://id.veracity.com/terms-of-use?mode=accept&redirect-url=' +
        environment.LUMINA_APP_BASE_URL +
        '&id=' +
        environment.SERVICE_ID +
        '&accepted=true';
    } else if (err.response.status === 401) {
      // set route params for error message
      this.router.navigate(['/unauthorized']);
      this.screen = 'unauthorized'
    } else {
      this.router.navigate(['/unauthorized']);
      this.screen = 'unauthorized'
    }
  }

  redirectAuth() {
    const loginUrl = `https://login.veracity.com/a68572e3-63ce-4bc1-acdc-b64943502e9d/oauth2/v2.0/authorize?p=b2c_1a_signinwithadfsidp&scope=openid%20offline_access%20https%3A%2F%2Fdnvglb2cprod.onmicrosoft.com%2F83054ebf-1d7b-43f5-82ad-b2bde84d7b75%2Fuser_impersonation&response_type=code&response_mode=query&client_id=${
      environment.LUMINA_APP_CLIENT_ID
    }&redirect_uri=${encodeURIComponent(
      environment.LUMINA_APP_BASE_URL
    )}%2Flogin&state=oQ8PLw0Rwd9nMIxN_yP6-`;
    const url = `${loginUrl}`;
    window.location.href = url;
  }

  validateCode() {
    var queryString = window.location.search;
    if (!queryString.includes('code') && !queryString.includes('state')) {
      this.redirectAuth();
    } else {
      var codeString = queryString.split('&code=')[1];
      var code = codeString.split('&')[0];
      this.getProfileDetails(code);
    }
  }

  updateContactDetails(isAccepted: boolean) {
    const dataToSend = {
      partyNumber: this.loggedPartyNumber,
      luminaTnCAccepted: isAccepted,
      associateAccounts: [],
      modifyLuminaAccess: [],
    };

    axios
      .post(environment.APP_BASE_URL + 'updateContactDetails', dataToSend, {
        headers: {
          'Ocp-Apim-Subscription-Key':
            environment.APP_Ocp_Apim_Subscription_Key,
        },
      })
      .then((response) => {
        // console.log(response);
        this.router.navigate(['/home']);
      })
      .catch((error) => {
        console.log('error occured' + error);
        this.router.navigate(['/unauthorized']);
        this.screen = 'unauthorized'
      });
  }

  getProfileDetails(code: any) {
    var config = {
      method: 'get',
      url: `${environment.APP_BASE_URL}getProfile`,
      headers: {
        'Ocp-Apim-Subscription-Key': environment.APP_Ocp_Apim_Subscription_Key,
        accesscode: code,
      },
    };
    axios(config)
      .then((response:any) => {
        console.log('response data is', response);
        localStorage.setItem('responseData', JSON.stringify(response))
        this.setLoginData(response.data);
        this.isLoginSubject.next(true);
        this.subscribeToRefreshToken(response.data);
        this.loggedPartyNumber = response.data.partyNumber
          ? response.data.partyNumber
          : '';
        this.setUserInfoData(
          new User(
            response.data.displayName,
            response.data.email,
            response.data.access_token,
            this.loggedPartyNumber,
            response.data.isLuminaAdmin,
            response.data.firstName,
            response.data.lastName,
            response.data.displayInitial
          )
        );
        this.appInsight.logEvent('SignIn', {
          Signin_Date: new Date(),
          User: response.data.displayName,
          Email: response.data.email,
        });
        this.appInsight.rootScope= response.data;
        this.appInsight.rootScope['Signin_Date'] = new Date();
        localStorage.setItem('luminaLoggedInUser', response.data.email);
        localStorage.setItem('signInTime', this.appInsight.rootScope['Signin_Date']);
        this.router.navigate(['/home']);
      })
      .catch((err) => this.handleErrors(err));
  }

  subscribeToRefreshToken = (loggedInuserData) => {
    //session timedout app insights capture
    console.log("signInTime1",this.appInsight.rootScope.Signin_Date);
    console.log("signInTime2",localStorage.getItem('signInTime'));
    this.appInsight.logEvent('Session_Timeout',{
      Signin_Date: localStorage.getItem('signInTime'),
      Signout_Date: new Date(),
      User: loggedInuserData.displayName,
      Email: loggedInuserData.email,
    })
    const options = {
      method: 'GET',
      headers: {
        'Ocp-Apim-Subscription-Key': environment.APP_Ocp_Apim_Subscription_Key,
        refreshtoken: loggedInuserData.refresh_token,
      },
      url: `${environment.APP_BASE_URL}getProfile`,
    };
    setInterval(() => {
      axios(options)
        .then((userInfo) => {
          const loggedPartyNumber = userInfo.data.partyNumber
            ? userInfo.data.partyNumber
            : '';
          this.setUserInfoData(
            new User(
              userInfo.data.displayName,
              userInfo.data.email,
              userInfo.data.access_token,
              loggedPartyNumber,
              userInfo.data.isLuminaAdmin,
              userInfo.data.firstName,
              userInfo.data.lastName,
              userInfo.data.displayInitial
            )
          );
          this.setLoginData(userInfo.data);
        })
        .catch((error) => {
          this.handleErrors(error);
        });
    }, 3000000); // 50 minutes
  };
}
