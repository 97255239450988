import { Component, OnInit } from '@angular/core';
import { IReportEmbedConfiguration, models, service } from 'powerbi-client';
import { ApiService } from 'src/app/services/api.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { TelemetryMonitoringService } from 'src/app/services/logging.service';

@Component({
  selector: 'app-lumina-analytics',
  templateUrl: './lumina-analytics.component.html',
  styleUrls: ['./lumina-analytics.component.css']
})
export class LuminaAnalyticsComponent implements OnInit {

  constructor(
    private authenticationService: AuthenticationService, private api: ApiService,
    private appInsight: TelemetryMonitoringService) { }

  reportClass = 'report-container';

  phasedEmbeddingFlag = false;

  reportConfig: IReportEmbedConfiguration = {};

  eventHandlersMap = new Map<string, (event?: service.ICustomEvent<any>) => void>([
    ['loaded', () => console.log('Report loaded')],
    ['rendered', () => console.log('Report rendered')],
    ['error', (event) => console.log(event?.detail)]
  ])

  ngOnInit(): void {
    // this.appInsight.logEvent('Visited Analytics page at : ', {
    //   Signin_Date: new Date(),
    // });
    // this.appInsight.logEvent('ProfileDetails', {
    //   User: this.appInsight.rootScope.displayName,
    //   Email: this.appInsight.rootScope.email
    // });
    this.trackPageView();
    this.authenticationService.getUserValue().subscribe((value) => {
      const userEmail = value.email;
      this.api.getBIDetails(userEmail).subscribe((reportDetails: any) => {
        this.reportConfig = {
          type: 'report',
          id: reportDetails.reportObject.reportId,
          embedUrl: reportDetails.reportObject.embeddedUrl,
          tokenType: models.TokenType.Embed,
          accessToken: reportDetails.reportObject.accessToken,
          settings: {
            panes: {
              filters: {
                expanded: false,
                visible: false
              }
            },
            background: models.BackgroundType.Transparent,
            layoutType: models.LayoutType.Custom,
            customLayout: {
              displayOption: models.DisplayOption.FitToPage
            }
          },
        }
      })
    });
  }
  trackPageView(){
    const profileDetails = {
      email: this.appInsight.rootScope.email, 
      user: this.appInsight.rootScope.displayName
    }
    this.appInsight.logPageView("Analytics page", profileDetails);
  }
}
