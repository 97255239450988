import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from '../snackbar/snackbar.component';


@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(private snackBar: MatSnackBar) { }

  public openSnackBar(message: string, action: string, snackType?: any) {
    const _snackType: any =
      snackType !== undefined ? snackType : 'Success';
    
    if(_snackType === 'Success') {
      this.snackBar.openFromComponent(SnackbarComponent, {
        duration: 5000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        data: { message: message, snackType: _snackType },
        panelClass: ['green-snackbar']
      });
    } else if(_snackType === 'Warn') {
      this.snackBar.openFromComponent(SnackbarComponent, {
        duration: 5000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        data: { message: message, snackType: _snackType },
        panelClass: ['red-snackbar']
      });
    }

  }
}
