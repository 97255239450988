<div class="block">
  <div class="containerHome">
    <div class="banner-img">
    <img
      class="home-img"
      src="../../assets/images/Resize_banner.jpg"
    />
    <div class="content">
      <h1 class="heading">LUMINA<sup class="sup">TM</sup> <br/> Improvement through awareness</h1>
      <!-- <p class="para">
        This digital tool provides performance benchmarking that turns your
        audit data into opportunities. Using Big Data analytics, we can provide
        a unique insight into the management system performance of companies
        like yours.
      </p> -->
      <p class="para">
        Improving your management system is a lot easier when you know where to
        look. Browse this digital dashboard to get insight on your biggest
        compliance risks, addressing non-conformities and performance
        benchmarking.
      </p>

      <div class="button-analytics">
        <button
          class="btn1 cursor"
          (click)="navigateToAnalytics(); scrollToTop()"
        >Go to LUMINA analytics
          <!-- <i class="fa-brands fa-elementor"></i> -->
          <!-- <img class="lum_ana"
          src="../../assets/images/chart-column-solid.svg"
        /> -->
          <!-- <svg
            class="lum_ana"
            xmlns="http://www.w3.org/2000/svg"
            height="1em"
            viewBox="0 0 512 512"
          >
            <path
              d="M32 32c17.7 0 32 14.3 32 32V400c0 8.8 7.2 16 16 16H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H80c-44.2 0-80-35.8-80-80V64C0 46.3 14.3 32 32 32zM160 224c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32s-32-14.3-32-32V256c0-17.7 14.3-32 32-32zm128-64V320c0 17.7-14.3 32-32 32s-32-14.3-32-32V160c0-17.7 14.3-32 32-32s32 14.3 32 32zm64 32c17.7 0 32 14.3 32 32v96c0 17.7-14.3 32-32 32s-32-14.3-32-32V224c0-17.7 14.3-32 32-32zM480 96V320c0 17.7-14.3 32-32 32s-32-14.3-32-32V96c0-17.7 14.3-32 32-32s32 14.3 32 32z"
            />
          </svg> -->
          <!-- <span class="goto">Go to Lumina Analytics</span> -->
        </button>
        <!-- <div> -->
        <button class="btn2 cursor" (click)="showPopup(); scrollToTop()">How to start?
          <!-- <a href="https://brandcentral.dnvgl.com/mars/embed?o=D0663719A4D160E2&c=10651&a=N">How to start ?</a> -->
          <!-- <img
            class="lum_ana"
            src="../../assets/images/circle-play-regular.svg"
          /> -->
          <!-- <span class="goto2">How to start ?</span> -->
        </button>
        <!-- </div> -->
      </div>
    </div>
  </div>
    <!-- <hr class="image-end"> -->
    <div class="content2">
      <!-- <div class="diff">What does it mean for your business?</div> -->
      <!-- <div class="diff">Rely on Lumina to help you improve</div> -->
      
      <div class="card-container row">
        <div class="cardh card1 col-lg-3">
          <div class="image_inline">
            <img
              class="immage_style"
              src="../../assets/images/box1.jpg"
            />
            <span class="img_text"> Prepare for certification </span>
          </div>
          <!-- <div class="blue_bg_small"> -->
            <div>
          <p class="small">
            Learn what most management systems fail to comply and direct efforts
            at areas most prone to findings. Browse per standard and industry.
          </p>
        </div>
          <!-- </div> -->
        </div>
        <div class="cardh card2 col-lg-3">
          <div class="image_inline">
            <img
              class="immage_style"
              src="../../assets/images/box2.jpg"
            />
            <span class="img_text">Address non-conformities</span>
          </div>
          <!-- <div class="blue_bg_small"> -->
            <div>
          <p class="small">
            View the most common root causes and fixes per clause and
            sub-clause. Reveal underlying issues to aid holistic improvements.
          </p>
        </div>
          <!-- </div> -->
        </div>
        <div class="cardh card3 col-lg-3">
          <div class="image_inline">
            <img
              class="immage_style"
              src="../../assets/images/box3.jpg"
            />
            <span class="img_text"> Improve performance</span>
          </div>
          <div>
          <p class="small">
            Benchmark against peers, other industries or among internal sites.
            Identify gaps, improve and build a consistent performance across
            your company.
          </p>
        </div>
        </div>
        <div class="cardh card4 col-lg-3">
          <div class="image_inline">
            <img
              class="immage_style"
              src="../../assets/images/box4.jpg"
            />
            <span class="img_text"> Check your progress</span>
          </div>
          <div>
          <p class="small">
            Analyze your improvement path. Check the trend view analysis over
            the past years.
          </p>
        </div>
        </div>
      </div>
    </div>
  </div>
<hr class="divider">
  <div class="diff" style="padding-left: 6%;margin-top: 2rem;">
    Access your other tools and services
    <p class="diff_child">
      As a DNV customer, you have access to a range of other tools and resources that can aid your certification journey before, during and after the audit.
    </p>
  </div>
  <div class="card-container2 ">
    <div class="cardd card11 col-lg-6">
      <div class="image_inline_next">
        <a href="https://www.dnv.com/assurance/Training/business-assurance-academy.html" target="_blank" class="image_link">
        <img
          class="image_height"
          src="../../assets/images/DNV Academy.svg"
        /></a>
        <div class="combined-text">
          <h2><a href="https://www.dnv.com/assurance/Training/business-assurance-academy.html" target="_blank">DNV Academy</a></h2>
          <p class="smaller">
            Practical courses that go beyond theory offered in classrooms and remotely.
          </p>
        </div>
      </div>
    </div>
    <div class="cardd card14 col-lg-6">
      <div class="image_inline_next">
        <a href="https://www.dnv.com/assurance/general/online-self-assessment-suite.html" target="_blank" class="image_link_plane">
        <img
          class="image_height_plane"
          src="../../assets/images/paper-plane-solid.svg"
        /></a>
        <div class="combined-text">
          <h2><a href="https://www.dnv.com/assurance/general/online-self-assessment-suite.html" target="_blank">Self-assessment suite</a></h2>
          <p class="smaller">
            Assess how well you know a standard or check the readiness of your management system.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="card-container2 ">
    <div class="cardd card12 col-lg-6">
      <div class="image_inline_next">
        <a href="https://www.dnv.com/assurance/digital-services/boost_my_audit.html" target="_blank" class="image_link">
        <img
          class="image_height"
          src="../../assets/images/BoostMyAudit.svg"
        /></a>
        <div class="combined-text">
          <h2><a href="https://www.dnv.com/assurance/digital-services/boost_my_audit.html" target="_blank">Boost My Audit</a></h2>
          <p class="smaller">
            Prepare for your audit by identifying Focus Areas most critical to your business success.
          </p>
        </div>
      </div>
    </div>
    <div class="cardd card13 col-lg-6">
      <div class="image_inline_next">
        <a href="https://www.dnv.com/assurance/general/e-advantage-login.html" target="_blank" class="image_link">
        <img
          class="image_height"
          src="../../assets/images/eAdvantage.svg"
        /></a>
        <div class="combined-text">
          <h2><a href="https://www.dnv.com/assurance/general/e-advantage-login.html" target="_blank">eAdvantage</a></h2>
          <p class="smaller">
            Manage your audit activities, certified sites, project status and closing of audit findings.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="card-container2 ">
    <div class="cardd card14 col-lg-6">
      <div class="image_inline_next">
        <a href="https://www.dnv.com/assurance/Management-Systems/Use-of-certification-marks.html" target="_blank" class="image_link">
        <img
          class="image_height"
          src="../../assets/images/eCertMarks.svg"
        /></a>
        <div class="combined-text">
          <h2><a href="https://www.dnv.com/assurance/Management-Systems/Use-of-certification-marks.html" target="_blank">eCertMark</a></h2>
          <p class="smaller">
            Manage and download your certification marks and guidance for digital print and use.
          </p>
        </div>
      </div>
    </div>
    <div class="cardd card13 col-lg-6">
      <div class="image_inline_next">
        <a href="https://www.dnv.com/assurance/viewpoint/index.html" target="_blank" class="image_link">
        <img
          class="image_height"
          src="../../assets/images/handshake-solid.svg"
        /></a>
        <div class="combined-text">
          <h2><a href="https://www.dnv.com/assurance/viewpoint/index.html" target="_blank">ViewPoint surveys</a></h2>
          <p class="smaller">
            Browse surveys on how companies build sustainability business performance.
          </p>
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="video-block">
    <div class="video-text"> -->
  <!-- <h2>Lumina™ videos</h2> -->
  <!-- </div> -->
  <!-- <div class="main-video"> -->
  <!--Video to be embedded-->
  <!-- <iframe
        frameborder="0"
        allowfullscreen
        webkitallowfullscreen
        mozallowfullscreen
        allow="accelerometer; camera; microphone; gyroscope; autoplay; clipboard-write; encrypted-media; picture-in-picture; fullscreen"
        width="100%"
        height="100%"
        src="https://brandcentral.dnvgl.com/mars/embed?o=D0663719A4D160E2&c=10651&a=N"
      ></iframe>
    </div> -->
  <!-- </div> -->
</div>
