import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HomeVideoComponent } from './home-video/home-video.component';
import { TelemetryMonitoringService } from 'src/app/services/logging.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  constructor(
    private router: Router,
    private appcomp: AppComponent,
    public dialog: MatDialog,
    private appInsight: TelemetryMonitoringService
    // public dialogRef: MatDialogRef<HomeVideoComponent>
  ) {}
  show: boolean = false;
  // constructor(private router: Router, private appcomp: AppComponent, private appInsight: TelemetryMonitoringService) {}

  ngOnInit(): void {
    this.trackPageView();
  }

  public navigateToAnalytics() {
    this.router.navigate(['/analytics']);
    console.log('clicked on analytics');
    this.appcomp.navChange('analytics');
  }

  public navigateToStartVideo() {
    this.router.navigate(['/howtostart']);
    console.log('clicked on howtostart');
    this.appcomp.navChange('howtostart');
    // let src="https://brandcentral.dnvgl.com/mars/embed?o=D0663719A4D160E2&c=10651&a=N";
  }
  public showPopup() {
    const dialogRef = this.dialog.open(HomeVideoComponent, {
      disableClose: false,
      data: {
        // link: 'https://brandcentral.dnvgl.com/mars/embed?o=D0663719A4D160E2&c=10651&a=N',
        link: 'https://brandcentral.dnv.com/mars/embed?o=5DD5790FD5E7B98F&c=10651&a=N'
      },
      width: '70%',
      height: '90%',
    });
  }

  // public closeDialog() {
  //   this.dialogRef.close();
  // }

  public scrollToTop() {
    window.scrollTo(0, 0);
  }
  trackPageView(){
    const profileDetails = {
      email: this.appInsight.rootScope.email, 
      user: this.appInsight.rootScope.displayName
    }
    // this.appInsight.logPageView("Home page", window.location.href, this.appInsight.rootScope.Email, this.appInsight.rootScope.displayName);
    this.appInsight.logPageView("Home page", profileDetails);
  }
}
