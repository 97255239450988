export class User {
    name:string = "";
    email: string = "";
    access_token="";
    party_Number="";
    isLuminaAdmin: boolean = false;
    firstName="";
    lastName="";
    initials="";
    constructor(name: string,email: string,access_token:string,party_Number:string,isLuminaAdmin:boolean,firstName:string,lastName:string, initials:string) {
        this.name = name;
        this.email = email;
        this.access_token = access_token;
        this.party_Number = party_Number;
        this.isLuminaAdmin = isLuminaAdmin;
        this.firstName = firstName;
        this.lastName = lastName;
        this.initials = initials;
      }
}



