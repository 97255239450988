import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  public baseUrlEndpoint = environment.APP_BASE_URL;
  public subscriptionKey = environment.APP_Ocp_Apim_Subscription_Key;
  public userEmail = '';
  public userAccessToken = '';
  public isAdminUser = false;
  public token = '';

  constructor(private http: HttpClient, private authenticationService: AuthenticationService) {
  }

  // Function disabled
  public getContacts(sendPartyNumber) {
    return this.http.get(this.baseUrlEndpoint + 'getContacts', {
      headers: {
        'Ocp-Apim-Subscription-Key': this.subscriptionKey
      }
    });
  }

  // Function disabled
  public getContactDetails(loggedInPartyNumber, loggedPartyNumber) {
    return this.http.get(this.baseUrlEndpoint + 'getContactDetails', {
      headers: {
        'Ocp-Apim-Subscription-Key': this.subscriptionKey
      }
    });
  }

  // Function disabled
  public createContactDetails(newData) {
    this.token = this.authenticationService.getUserSubject.value.access_token;
    return this.http.post(this.baseUrlEndpoint + 'createContactDetails', newData, {
      headers: {
        'Ocp-Apim-Subscription-Key': this.subscriptionKey,
        'accesstoken' : this.token
      }
    });
    
  }

  // Function modified with no params
  public updateContactDetails(updatedData) {
    this.token = this.authenticationService.getUserSubject.value.access_token;
    return this.http.post(this.baseUrlEndpoint + 'updateContactDetails' ,null, {
      headers: {
        'Ocp-Apim-Subscription-Key': this.subscriptionKey,
        'accesstoken' : this.token
      }
    });
  }

   // Function modified with no params
  public getBIDetails(email) {
    this.token = this.authenticationService.getUserSubject.value.access_token;
    return this.http.get(this.baseUrlEndpoint + 'getReportDetails', {
      headers: {
        'Ocp-Apim-Subscription-Key': this.subscriptionKey,
        'Authorization' : `Bearer ${this.token}`
      }
    });
  }

  public submitRequest(newData) {
    this.token = this.authenticationService.getUserSubject.value.access_token;
    return this.http.post(this.baseUrlEndpoint + 'sendSupportEmail', newData, {
      headers: {
        'Ocp-Apim-Subscription-Key': this.subscriptionKey,
        'Authorization' : `Bearer ${this.token}`
      }
    });
    
  }

}
