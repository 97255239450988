import { Component, HostListener, Inject, OnDestroy, OnInit, VERSION } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { faTwitter, faFacebookF, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { Observable } from 'rxjs';
import { LuminaConstants } from './lumina-constants';
import { AuthenticationService } from './services/authentication.service';
import { ApiService } from './services/api.service';
import { DOCUMENT } from '@angular/common';
import { environment } from 'src/environments/environment';
import screenfull from 'screenfull';
import { TelemetryMonitoringService } from './services/logging.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit, OnDestroy {

  @HostListener('window:beforeunload', [ '$event' ])
  beforeUnloadHandler(event) {
    console.log("user details",this.appInsight.rootScope)
    if(this.appInsight.rootScope && this.appInsight.rootScope.Signin_Date && this.appInsight.rootScope.displayName && this.appInsight.rootScope.email){
      this.appInsight.logEvent('BrowserEvent', {
        Signin_Date: this.appInsight.rootScope.Signin_Date,
        Signout_Date: new Date(),
        User: this.appInsight.rootScope.displayName,
        Email: this.appInsight.rootScope.email,
      });
    }
  }

  isFullScreenShown: boolean = false; // hidden by default

  title = 'Lumina-App-UI';
  version = VERSION.full;

  public userName = '';
  public currentLink = 'home';
  public faFacebook = faFacebookF;
  public faTwitter = faTwitter;
  public faLinkedin = faLinkedin;
  public isAdmin = false;
  public isDnvEmail = false;
  public initials = '';
  public name = '';
  public email = '';
  public showHeaderFooter = false;
  public userEmail = '';

  isLoggedIn: Observable<boolean>;
  isShow: boolean = false;
  elem: any;
  inFullScreen: boolean = false;


  constructor(public dialog: MatDialog,
    private authenticationService: AuthenticationService, private api: ApiService,
    @Inject(DOCUMENT) private document: any,
    private appInsight: TelemetryMonitoringService,
    private router: Router,
    ) {
    this.elem = document.documentElement;
    this.version = LuminaConstants.APP_VERSION;
    this.isLoggedIn = authenticationService.isLoggedIn();
    this.authenticationService.isLoggedIn().subscribe((value) => {
      this.isShow = value;
      console.log("value changed in app component " + this.isShow)
    });
  }

  ngOnDestroy(): void {
    throw new Error('Method not implemented.');
  }

  @HostListener('document:fullscreenchange', ['$event'])
  @HostListener('document:webkitfullscreenchange', ['$event'])
  @HostListener('document:mozfullscreenchange', ['$event'])
  @HostListener('document:MSFullscreenChange', ['$event'])

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    console.log(event);
    console.log("key press event" + "  escape");
    if (document.fullscreenElement) {
      console.log("Escape detected minimze reports");
      this.minimize();
    }
  }

  ngOnInit(): void {
    console.log('screen', this.authenticationService.screen);
    setTimeout(() => {
      if(this.authenticationService.screen == 'unauthorized') {
        this.showHeaderFooter = false
      }
      console.log('screen', this.authenticationService.screen);
    }, 1000)
   
   this.screenModeCheck();
    this.elem = document.documentElement;
    console.log("inside ngoninit");
    console.log("env is "+environment.ENV );
    this.authenticationService.getUserValue().subscribe((value) => {
     // console.log(value);
     this.isAdmin = value.isLuminaAdmin;
      this.initials = value.initials;
      this.name = value.name;
      if (value.email !== '') {
        this.email = value.email;
        this.showHeaderFooter = true;
      }
      if(value.email){
        const domain = value?.email?.split('@');
        domain[1] === "dnv.com" ? this.isDnvEmail = true : this.isDnvEmail = false;
      }
    });
    
  }

  public logoutUser() {
    this.appInsight.logEvent('SignOut', {
      Signin_Date: this.appInsight.rootScope.Signin_Date,
      Signout_Date: new Date(),
      User: this.appInsight.rootScope.displayName,
      Email: this.appInsight.rootScope.email,
    });
    this.authenticationService.logout();
  }

  public navChange(linkName: string) {   
    if (linkName == 'analytics' || linkName == 'analytics-internal') {
      this.isFullScreenShown = true;
    }
    else {
      this.isFullScreenShown = false;
    }
    this.currentLink = linkName;

    // check mobile home clicked
    var x = document.getElementById("luminaTopNav");
    if(x){
      if (x.className === "fluidNav") {
        if(linkName=="home"){
          return;
        }
      }
    } 
    this.bringMobileNav();
  }

  public routeToDNV() {
    window.open(LuminaConstants.HOME_URL);
  }

  public scrollToTop() {
    console.log("support clicked");
    this.navChange("support");
    window.scrollTo(0, 0);
  }

  public openProfile() {
    window.open(LuminaConstants.PROFILE_URL);
  }

  public openTC() {
    window.open("./assets/Lumina_TC.pdf");
  }

  
  public bringMobileNav() {
    var x = document.getElementById("luminaTopNav");
    if(x){
      if (x.className === "fluidNav") {
        x.className += " responsive";
      } else {
        x.className = "fluidNav";
      }
    }
  }

  @HostListener('document:fullscreenchange', ['$event'])
  @HostListener('document:webkitfullscreenchange', ['$event'])
  @HostListener('document:mozfullscreenchange', ['$event'])
  @HostListener('document:MSFullscreenChange', ['$event'])
  
  fullscreenmodes(event) {
    console.log("screen mode check");
    this.screenModeCheck();
  }
  screenModeCheck() {
    if (screenfull.isFullscreen) {
      console.log("in full screen ")
      //fullscreen
      this.inFullScreen = true;
      this.showHeaderFooter = false;
    } else {
      //not in full screen
      console.log("not in full screen ")
      this.inFullScreen = false;
      if (this.email !== '') {
        this.showHeaderFooter = true;
      }

    }
  }
  maxmize() {
 
    this.showHeaderFooter = false;
    if (screenfull.isEnabled) {
      console.log("request maximize reports");
      screenfull.request();
    //  this.screenModeCheck();
    } else {
      // Ignore or do something else
    }
    // if (this.elem.requestFullscreen) {
    //   this.elem.requestFullscreen();
    // } else if (this.elem.mozRequestFullScreen) {
    //   /* Firefox */
    //   this.elem.mozRequestFullScreen();
    // } else if (this.elem.webkitRequestFullscreen) {
    //   /* Chrome, Safari and Opera */
    //   this.elem.webkitRequestFullscreen();
    // } else if (this.elem.msRequestFullscreen) {
    //   /* IE/Edge */
    //   this.elem.msRequestFullscreen();
    // }
  }
  
  /* mimize full screen */
  minimize() {
    console.log("minimize reports");
    this.showHeaderFooter = true;
    if (screenfull.isEnabled) {
      if(screenfull.isFullscreen){
        console.log("request minimize reports");
        screenfull.exit();
      //  this.screenModeCheck();
      }
    }
    // if (this.document.exitFullscreen) {
    //   this.document.exitFullscreen();
    // } else if (this.document.mozCancelFullScreen) {
    //   /* Firefox */
    //   this.document.mozCancelFullScreen();
    // } else if (this.document.webkitExitFullscreen) {
    //   /* Chrome, Safari and Opera */
    //   this.document.webkitExitFullscreen();
    // } else if (this.document.msExitFullscreen) {
    //   /* IE/Edge */
    //   this.document.msExitFullscreen();
    // }
  }

  navigateToSupport() {
    this.router.navigate(['support'])
  }
}

