import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class TelemetryMonitoringService {
  appInsights: ApplicationInsights;
  rootScope:any = {};
  constructor() {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.appInsights.instrumentationKey,
        enableAutoRouteTracking: true, // option to log all route changes
      },
    });
    this.appInsights.loadAppInsights();
  }

  logPageView(name?: string, properties?: any, mail?: string, username?: string) {
    // option to call manually
    this.appInsights.trackPageView({
      name: name,
      properties: properties,
      // email: mail,
      // User: username
    });
    console.log("name", name, "properties", properties);
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    this.appInsights.trackEvent({ name: name }, properties);
  }

  logMetric(
    name: string,
    average: number,
    properties?: { [key: string]: any }
  ) {
    this.appInsights.trackMetric({ name: name, average: average }, properties);
  }

  logException(exception: Error, severityLevel?: number) {
    this.appInsights.trackException({
      exception: exception,
      severityLevel: severityLevel,
    });
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    this.appInsights.trackTrace({ message: message }, properties);
  }
}

// function Injectable() {
//     throw new Error('Function not implemented.');
// }
