<div class="block" style="height: 130vh;">
    <div class="heading-support">
        <span class="head-sup">Support page
        </span>
    </div>

    <div class="container">
        <div class="">
            <div class="col-lg-12 col-md-12 col-sm-12 support-boxes" >
                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 boxes">
                    <div class="box-container" (click)="navigateToFAQ()">
                        <div class="icon-container">
                            <span class="material-symbols-outlined">
                                help
                                </span>
                            <!-- <img src="./../assets/icons/faq-icon.svg"> -->
                        </div>
                        <div class="text-container">
                            <p>Frequently asked questions</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 boxes">
                    <div class="box-container" (click)="navigateToTutorial()">
                        <div class="icon-container">
                            <span class="material-symbols-outlined">
                                live_tv
                                </span>
                            <!-- <img src="./../assets/icons/tutorial-icon.svg" > -->
                            <!-- <img src="./../assets/icons/play-solid.svg" class="img2"> -->
                        </div>
                        <div class="text-container">
                            <p>Tutorial and videos</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4 boxes col-xs-12">
                    <div class="box-container" (click)="openSupportRequest()">
                        <div class="icon-container">
                            <!-- <img src="./../assets/icons/support-icon.svg"> -->
                            <span class="material-symbols-outlined">
                                edit
                                </span>
                        </div>
                        <div class="text-container">
                            <p>Submit a support request</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div *ngIf="isOpenRequest">
        <div>
            <h2  class="support-request">Support request </h2>
            <p class="support-para">This form is for LUMINA support requests</p>
        </div>

        <form [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup)"  class="support-form">
            <mat-label>Your email *</mat-label>
            <mat-form-field class="form-element" appearance="outline" floatLabel="always"  class="mail-bottom">
               
                <input matInput placeholder="Email Address" formControlName="email" name="email" >
                <mat-error *ngIf="checkError('email', 'required')">Email is required</mat-error>
                <mat-error *ngIf="checkError('email', 'email')">Email is invalid</mat-error>
            </mat-form-field>
              <mat-label >Description *</mat-label>
              <mat-form-field class="form-element" appearance="outline" floatLabel="always"  class="description-bottom">
               
                <textarea matInput placeholder="Description" name="description" rows="5" cols="15" formControlName="description"></textarea>
                <mat-error *ngIf="checkError('description', 'required')">Description is required</mat-error>
                <!-- <mat-error *ngIf="checkError('email', 'email')">Email is invalid</mat-error> -->
              </mat-form-field>
            <div class="form-element">
      <button mat-raised-button color="primary" type="submit" class="button"  class="submit-button">Send your request</button>
    </div>
            
          </form>
    </div>

    <!-- <div class="country">
        <span class="country-select">Select Country</span>
        <div class="countrydrp">
            <mat-select placeholder="Type Search and Dropdown" class="form-control" [(ngModel)]="selectedCountry"
                (selectionChange)="selectedCountry = $event.value;countryChanged($event)">
                <mat-option *ngFor="let country of countryEmailsArray" [value]="country.countryName">
                {{country.countryName}}
                </mat-option>
            </mat-select>
        </div>

        <div class="support-email-text" *ngIf="selectedCountryEmail !== ''">
            <span class="support-email">Support E-mail</span>
            <span class="show-email" (click)="contactSupport()">{{selectedCountryEmail}}</span>
        </div>

    </div> -->

</div>
