<div class="container-fluid p-0" style="width: 100%;height: 100%">
  <div class="">
    <div style="height: 100%;" class="">
      <img src="../../../assets/images/401background.jpg" class="background-img"/>
    </div>
  <div class="error-container ">
    <div class="image_align">
      <div class="lock-icon">
        <img src="../../../assets/icons/lock-solid.svg"/>
      </div>
      <div class="text-section">
        <h1>Missing permission</h1>
        <p>Thank you for your interest in LUMINA<sup>TM</sup>. This tool is reserved for DNV
        customers only. If your company is a DNV customer already, please contact
        us at luminasupport.global&#64;dnv.com.</p>
        <br />
        <p>Should you want to know more about LUMINA and DNV's services, please visit
        our website <a href="https://www.dnv.com" target="_blank">dnv.com</a>.</p>
      </div>
    </div>
  </div>
  </div>
</div>