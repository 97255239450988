import { Injectable } from '@angular/core';

import { AuthenticationService } from '../services/authentication.service';



@Injectable({ providedIn: 'root' })
export class AuthGuard  {
    constructor(
        private authenticationService: AuthenticationService
    ) { }

    canActivate() {
        const isLoggedIn = this.authenticationService.isLoginSubject.value;
        if (isLoggedIn) {
            return true;
        }
        else {
            this.authenticationService.redirectAuth();
            return false;
        }
    }
}