import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.css']
})
export class UnauthorizedComponent implements OnInit {

  constructor(private authenticationService: AuthenticationService){ }

  ngOnInit(): void {
    this.authenticationService.screen = 'unauthorized';
    setInterval(() => {
      this.authenticationService.logout()
    },10000); // 10sec
  }

}
