import { Component, OnInit } from '@angular/core';
import * as countryData from '../../../assets/countryEmails.json';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { ToastrService } from 'ngx-toastr';
import axios from 'axios';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-lumina-support',
  templateUrl: './lumina-support.component.html',
  styleUrls: ['./lumina-support.component.css']
})
export class LuminaSupportComponent implements OnInit {
  isOpenRequest: boolean = false;
  formGroup!: FormGroup;
  userMail: any;
  constructor(private route: Router, private formBuilder: FormBuilder, private apiService: ApiService, private toastr: ToastrService, private authenticationService: AuthenticationService) { }

  public countryEmailsArray;
  public selectedCountry = '';
  public selectedCountryEmail = '';

  ngOnInit(): void {
 
    //localStorage.setItem('luminaLoggedInUser', 'ba.apps.demo+user4@gmail.com')
    this.countryEmailsArray = (countryData as any).default;
    this.userMail = localStorage.getItem('luminaLoggedInUser');
    console.log('this.usermail', this.userMail);
    this.createForm();
  }


  createForm() {
    this.formGroup = new FormGroup({
      email: new FormControl(this.userMail, [ Validators.email, Validators.required]) ,
      description: new FormControl('', [Validators.required])
      // 'email': [null, [Validators.required, Validators.email]],
      // 'description': [null, [Validators.required, Validators.minLength(5), Validators.maxLength(10)]],
      // 'validate': ''
    });
  }
  public countryChanged(cname) {
    this.selectedCountryEmail = this.countryEmailsArray.find(item => item.countryName === cname.value).supportEmail;
  } 

  public contactSupport() {
    const toEmail = this.selectedCountryEmail;
    window.open(encodeURI('mailto:' + toEmail));
  }


  navigateToFAQ() {
      this.route.navigate(['faq'])
  }

  navigateToTutorial() {
    this.route.navigate(['tutorial'])
  }

  openSupportRequest() {
    this.isOpenRequest = true
  }

  onSubmit(form:any){
    let token = this.authenticationService.getUserSubject.value.access_token;
    console.log('form', form);
    const payload = {
      email: form.value.email,
      description: form.value.description
    }
    if(form.status != "INVALID") {
      axios
      .post(environment.APP_BASE_URL + 'sendSupportEmail', payload, {
        headers: {
          'Ocp-Apim-Subscription-Key':
            environment.APP_Ocp_Apim_Subscription_Key,
            'Authorization' : `Bearer ${token}`
        },
      })
      .then((response) => {
        // console.log(response);
       this.showSuccess()
      })
      .catch((error) => {
        console.log('error occured' + error);
        // this.router.navigate(['/unauthorized']);
        // this.screen = 'unauthorized'
      });
  } else {
    this.showError()
  }
  }

  public checkError = (controlName: string, errorName: string) => {
    return this.formGroup.controls[controlName].hasError(errorName);
  }

  showSuccess() {
    this.toastr.success('Your request has been submitted successfully', '');
  }

  showError() {
    this.toastr.error('Please fill the mandatory fields', '');
  }
}
