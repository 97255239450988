<section>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
    <header class="first-section"  *ngIf="showHeaderFooter">
        <mat-toolbar class="topBar">
            <div class="block first">
                <img class="logo" src="../assets/images/DNV.png" (click)="routeToDNV()">
            </div>
            <div class="block avatar-space text-right">
               
                <div class="blob logout" (click)="logoutUser()">
                    <span class="pointer text">Logout</span>
                </div>
                <div  class="blob-profile pointer" matTooltip="Click to view profile"
                (click)="openProfile()">{{initials}}
                <!-- <ngx-avatar class="pointer" matTooltip="Click to view profile" name="{{userName}}"
                initialsSize="2" bgColor="#0F204B" fgColor="white" size="40" (click)="openProfile()"></ngx-avatar> -->
            </div>
            </div>
        </mat-toolbar>

        <div class="fluidNav" id="luminaTopNav"  style="margin-top: -5px;">
            <nav class="navbar_responsive">
                <span class="navlink" (click)="navChange('home')" routerLink="/home" routerLinkActive="active"
                    ariaCurrentWhenActive="page"  style="margin-left: 2.9rem;">Home</span>
                <span class="navlink" (click)="navChange('analytics')" routerLink="/analytics" routerLinkActive="active"
                    ariaCurrentWhenActive="page">Analytics</span>
                <span class="navlink" (click)="navChange('overlay')" routerLink="/tutorial" routerLinkActive="active"
                    ariaCurrentWhenActive="page">More</span>
                <!--<span *ngIf="isDnvEmail" class="navlink" (click)="navChange('analytics-internal')" routerLink="/analytics-internal" routerLinkActive="active"
                    ariaCurrentWhenActive="page">Analytics Internal</span>-->
                <span *ngIf="isAdmin" class="navlink" (click)="navChange('admin')" routerLink="/admin"
                    routerLinkActive="active" ariaCurrentWhenActive="page">Admin</span>
                    <span class="pointer navlinkLogout" (click)="logoutUser()">Logout</span>
                <span style="margin-right: 20px" href="javascript:void(0);" class="icon" (click)="bringMobileNav()">
                    <i class="fa fa-bars"></i>
                </span>

                <button class="navlinkAccount" mat-button [matMenuTriggerFor]="menu">
                    <mat-icon class="navlinkAccount" >
                    <svg class="fa-user" version="1.2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" overflow="visible" preserveAspectRatio="none" viewBox="0 0 450 514" width="14.000000000000002" height="17"><g transform="translate(1, 1)"><path d="M313.6 288c-28.7 0-42.5 16-89.6 16-47.1 0-60.8-16-89.6-16C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4zM416 464c0 8.8-7.2 16-16 16H48c-8.8 0-16-7.2-16-16v-41.6C32 365.9 77.9 320 134.4 320c19.6 0 39.1 16 89.6 16 50.4 0 70-16 89.6-16 56.5 0 102.4 45.9 102.4 102.4V464zM224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm0-224c52.9 0 96 43.1 96 96s-43.1 96-96 96-96-43.1-96-96 43.1-96 96-96z" vector-effect="non-scaling-stroke"></path></g></svg>
                </mat-icon>
                </button>
                <button class="navlinkAccount1" mat-button (click)="navigateToSupport()">
                    <img
      class="home-img"
      src="../../assets/icons/circle-question-regular.svg"
      style=""
    />
                </button>

                <!-- <button class="navlinkAccount" mat-button [matMenuTriggerFor]="menu">
                    <mat-icon class="navlinkAccount">account_circle</mat-icon>
                </button> -->
             
                <mat-menu class="navlinkAccountMenu" #menu="matMenu">
                    <button mat-menu-item>
                        <div  (click)="openProfile()" class="profile">YOUR PROFILE</div>
                        <!-- <div class="profile">YOUR PROFILE</div> -->
                    </button>
                    <div class="profile" style="cursor: auto; background-color:white;" mat-menu-item>{{name}}</div>
                    <hr size="" width=""   color="#009FDA">
                    <button mat-menu-item>
                        <div class="logoutMenu" (click)="logoutUser()">
                            <span class="pointer text">Logout</span>
                        </div>
                    </button>
                </mat-menu>

                <span class="navlinkRight">|</span>
                <span class="navlinkRight" 
                    >LUMINA<sup class="sup">TM</sup></span>
            </nav>
        </div>


    </header>

 <div class="reportMaxIcon" *ngIf="isFullScreenShown" [ngStyle]="{float:'right'}">
        <mat-icon class="screen" *ngIf="!inFullScreen" [ngStyle]="{float:'right'}" (click)="maxmize()">fullscreen
        </mat-icon>
        <mat-icon [ngStyle]="{float:'right'}" class="screen" *ngIf="inFullScreen" (click)="minimize()">
            fullscreen_exit</mat-icon>
    </div>
    

    <div>
        <router-outlet></router-outlet>
    </div>

    <div class="footer" style="position: relative;"  *ngIf="showHeaderFooter">
        <div class="float-left">
            <ul class="abtDNV navbar-nav mt-2 mt-lg-0 nav nav-tabs">
                <li class="nav-item tab text-color"></li>
                <li class="nav-item mr-auto"><a target="_blank" class="nav-link pr-0"
                        href="https://www.dnv.com/about/index.html" title="" rel="noreferrer">About
                        DNV</a></li>
                <!-- <li class="nav-item mr-auto"><a  class="nav-link pr-0"
                         title=""
                        rel="noreferrer">Contact DNV</a></li> -->
                        <!-- <li class="nav-item mr-auto"><a target="_blank" class="nav-link pr-0"
                            href="https://www.dnv.com/contact/index.html" title=""
                            rel="noreferrer">Contact DNV</a></li> -->
                <li class="nav-item mr-auto"><a target="_blank" class="nav-link pr-0"
                        href="https://store.veracity.com/terms/lumina-performance-benchmarking" title=""
                        rel="noreferrer">Terms & Conditions</a></li>

                        
                        <!-- <li class="nav-item mr-auto"><a class="nav-link pr-0"
                             (click)="scrollToTop()" title="FAQ" rel="noreferrer">FAQ</a></li>
                            <li class="nav-item mr-auto">
                            <a (click)="scrollToTop()"  class="nav-link pr-0"
                                 title="Support" rel="noreferrer">Support</a></li> -->
                                 <!-- <li class="nav-item mr-auto"><a class="nav-link pr-0"
                                    (click)="scrollToTop()" title="FAQ" rel="noreferrer">FAQ</a></li>
                                   <li class="nav-item mr-auto">
                                   <a (click)="scrollToTop()"  class="nav-link pr-0"
                                        title="Support" rel="noreferrer">Support</a></li> -->

                <li class="nav-item mr-auto"><a target="_blank" class="nav-link pr-0"
                        href="https://www.dnv.com/terms/index.html" title="" rel="noreferrer">Terms of use</a></li>
                <li class="nav-item mr-auto"><a target="_blank" class="nav-link pr-0"
                        href="https://www.dnv.com/privacy/index.html" title="" rel="noreferrer">Privacy statement</a>
                </li>
                <li class="nav-item mr-auto"><a target="_blank" class="nav-link pr-0"
                        href="https://www.dnv.com/copyright/index.html" title="" rel="noreferrer">Copyright</a></li>
            </ul>
        </div>
        <div class="float-right">
            <li class="nav-item tabCopyright text-color">© DNV AS 2023 |</li>
            <ul class="navbar-nav ml-auto mt-2 mt-lg-0 nav nav-tabs">
              
                <!-- <li class="nav-item tab text-color">|</li> -->
                <li class="nav-item mr-auto"><a target="_blank" class="nav-link pr-0"
                        href="https://www.linkedin.com/company/dnv" title="" rel="noreferrer"><img
                            src="./assets/icons/Button-LinkedIn.svg" alt="LinkedIn"></a></li>
                <li class="nav-item mr-auto"><a target="_blank" class="nav-link pr-0"
                        href="https://twitter.com/DNV_Group" title="" rel="noreferrer"><img
                            src="./assets/icons/Button-Twitter.svg" alt="Twitter"></a></li>
                <li class="nav-item mr-auto"><a target="_blank" class="nav-link pr-0"
                        href="https://www.facebook.com/dnvofficial" title="" rel="noreferrer"><img
                            src="./assets/icons/Button-Facebook.svg" alt="Facebook"></a></li>
            </ul>
        </div>
    </div>
</section>

<script>

</script>