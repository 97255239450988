import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lumina-faq',
  templateUrl: './lumina-faq.component.html',
  styleUrls: ['./lumina-faq.component.css']
})
export class LuminaFaqComponent implements OnInit {

  constructor() { }

  public administrationFAQ;
  public luminaAnalyticsFAQ;
  public dataSecurityFAQ;

  ngOnInit(): void {

    this.administrationFAQ = [
      {
        'ques': 'How do I share Lumina Access with my colleagues?',
        'ans': `You can share access with your colleagues from the Admin page. If find the name of the colleague you would like to share access with, just expand on their name and under Associated Accounts section, you can check the Lumina Access flag against the Accounts you would like your colleague to have access of. Then you click Save and the access is granted and your colleague will receive email notifications to access the application.<br>
                In case you don’t fine your colleague in the list of existing contacts, you can click on ‘Create new contact’ button and add your colleague’s details and provide access.`
      },
      {
        'ques': 'How can I make my colleague an Admin?',
        'ans': `On the admin page, when you expand on your colleague’s name, you can find a checkbox called Admin. Checking this checkbox will provide admin access to your colleagues.`
      }
    ];

    this.luminaAnalyticsFAQ = [
      {
        'ques': 'What is Lumina Analytics?',
        'ans': `Lumina Analytics is the tool which allows you to setup your benchmarks and then browse across a variety of reports that allow you to analyse the performance of your management system and compare your results to the entire market.`
      },
      {
        'ques': 'What is Benchmark filter?',
        'ans': `Lumina allows you to compare your organisations results with those of the entire market, industry, geography or a combination of these. The wanted benchmark can be defined by setting the filters in the Lumina Analytics page.`
      },
      {
        'ques': 'What is EA/NACE code filter?',
        'ans': `EA codes stand for European Accreditation industry sector codes. NACE for “Statistical Classification of Economic Activities in the European Community”. They are widely accepted industry classification codes. Using the EA/NACE code filters in Lumina, users can set up their benchmark within a specific industry sector.`
      },
      {
        'ques': 'What can I find on the Overview page?',
        'ans': `Overview shows a predefined set of charts which give a broader view of the average performance of the companies certified to a given Standard, in the last 12 rolling months.`
      },
      {
        'ques': 'What can I find on the Analysis page?',
        'ans': `Analysis provides a number of reports and views allowing the comparison of the performance of companies in a specific industry sector. It also shows the most frequent areas of failure for companies in the specific industry sector, as well as statistics about the most common root causes and corrective actions.`
      },
      {
        'ques': 'What can I find on the Benchmark page?',
        'ans': `Benchmark offers a number of reports and views allowing companies to compare their performance with the selected benchmark group. For multi-site companies, benchmarking among own sites’ performance is also possible as well as trend analysis.`
      },
      {
        'ques': 'What is a Finding, and what is the difference between Finding and Nonconformity?',
        'ans': `With “Finding” we refer to all those pain points identified by Auditors while verifying companies’ compliance to a Standard. Findings include nonconformities and other types of (less severe) pain points (i.e. observations and opportunities for improvement). “Nonconformity” includes Major nonconformities (Cat1), the most serious findings, and Minor nonconformities (Cat2).`
      },
      {
        'ques': 'What is MS-pi?',
        'ans': `The MS-pi (Management System performance index) consolidates multiple elements into a synthetic metric, ranging from 0 to 100 (100 being the best), allowing you to gain immediate understanding of the overall performance of your organization’s Management System.`
      },
      {
        'ques': 'What is the average no. of Findings in an audit day?',
        'ans': `The so-called Findings/day is a simple metric, resulting from the ratio between the total number of findings collected in the selected period, and the number of audit days in the same period. It allows comparison between companies or entities, irrespective of their size and nature.`
      },
      {
        'ques': 'Can I export Lumina reports?',
        'ans': `Currently, Power BI embedded reports do not allow the export of charts or tables. However, screenshots can be taken by users`
      },
      {
        'ques': 'Where can I find practical information about Lumina?',
        'ans': `More information about how to access and how to use Lumina App can be found in the interactive tutorial, available at the following URL: <a href="http://ior.ad/7l6a" target="_blank">http://ior.ad/7l6a</a>`
      },
      {
        'ques': 'How can I ask for support?',
        'ans': `Drop an email to luminasupport.global@dnv.com, and we will get back to you as soon as possible.`
      }
    ];

    this.dataSecurityFAQ = [
      {
        'ques': 'Is my company data secure?',
        'ans': `When it comes to data security, we give it the highest importance. Your company’s transactional data is never exposed through the Lumina App under any circumstance. The backend data is highly secured within the domain with access to backend data is fully encrypted. Application transactions are secured using SSL protocols with centrally managed single-sign-on, vulnerability scanning, and monthly security patching.`
      },
      {
        'ques': 'Is my company data confidentiality protected?',
        'ans': `Lumina protects the confidentiality of the data that is used for statistical analysis. The application does not expose in any form the data related to the audits of our customers. The information available on the dashboard is purely statistical and maintains the full anonymity of our customers. Lumina also embeds various checks to ensure that the users are unable to view the reports if the filters added are resulting in very few records which is less than the threshold, to ensure both data confidentiality and statistical reliability. Power BI reports are also secured by row-level security ensuring each user is able to see only the data belonging to their own company.`
      },
    ];
  }

}
